@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Urbanist:wght@100&display=swap');
@import url('https://fonts.cdnfonts.com/css/open-sans');
@import url('https://fonts.cdnfonts.com/css/poppins');

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

html {
  font-family: 'Open Sans', sans-serif;
}


       
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.firstWin-button-gradient {
  font-weight: 800;
  background: radial-gradient(69.71% 480.16% at 14.71% -89.13%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}


.downloads {
  background-color: rgba(240, 240, 240, 0.05);
}

.shadowbox-background {
  background: linear-gradient(180deg, rgba(20, 21, 23, 0.00) 0%, #141517 100%);
}


.sw_top_2 {
    background: black;
}

.shadow_more {
  box-shadow: 0px 10px 20px 0px #EEC16980;
}


.table_tr > td { 
  background: #694EDC;
 }

 .shadow-roadmap { 
  background: radial-gradient(53.71% 769.09% at 12.13% -169.21%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
 }

 @media (max-width: 767px) {
  .shadow-roadmap { 
    background: radial-gradient(53.71% 769.09% at 12.13% -169.21%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}

 .roadmap_point {
  background: linear-gradient(180.92deg, rgba(255, 255, 255, 0.05) 6.59%, rgba(255, 255, 255, 0.015) 93.21%);
 }

.roadmap_number {
  background: linear-gradient(180.92deg, rgba(255, 255, 255, 0.1) 6.59%, rgba(255, 255, 255, 0.03) 93.21%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

.roadmap_number_activate {
  background: radial-gradient(347.83% 77.69% at -89.13% 27.98%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

.roadmap_point_activate {
  background: radial-gradient(67.17% 873.97% at 15.17% -199.11%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.roadmap_point_activate_shadow {
  box-shadow: 40px 40px 40px 0px #3BCDCC66 inset;
  box-shadow: -10px -10px 25px 0px #B336FF66 inset;
}

.roadmap_shadow_mob {
  background: linear-gradient(-60deg, rgba(20, 21, 23, 0.00) 70.73%, #141517 100%), linear-gradient(180deg, rgba(20, 21, 23, 0.00) 70.73%, #141517 92%), linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
}

.roadmap_shadow {
  background:linear-gradient(-90deg, rgba(20, 21, 23, 0.00) 75%, #141517 100.01%), linear-gradient(90deg, rgba(20, 21, 23, 0.00) 75%, #141517 100.01%), linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
}

.faq_gradient {
  background: radial-gradient(67.17% 873.97% at 15.17% -199.11%, #28FD92 0%, #4BA1FE 50%, #B235FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.modal_gradient {
  background: linear-gradient(0deg, #141517, #141517), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));

}